<template lang="html">
  <div class="fake-waveform">
    <div v-for="(bar, index) in bars" class="bar" :key="index" :style="{ height: bar.height }" />
  </div>
</template>

<script>
export default {
  beforeUnmount() {
    window.removeEventListener('resize', this.generate, { passive: true });
    window.clearInterval(this.barInterval);
  },
  computed: {
    amplitude() {
      if (this.mood === 0) return 0;
      return 10 * this.mood + 2;
    },
  },
  created() {
    window.addEventListener('resize', this.generate, { passive: true });
  },
  data() {
    return {
      bars: [],
      barInterval: null,
      remBase: Number.parseFloat(getComputedStyle(document.documentElement).fontSize),
    };
  },
  methods: {
    generate() {
      window.clearInterval(this.barInterval);
      this.bars = [];
      const maxWidth = Math.min(512, this.$el.offsetWidth);
      const barWidth = 0.5 * this.remBase + 1 * this.remBase;
      const numOfBars = maxWidth / barWidth;

      for (let i = 0; i < numOfBars; i += 1) this.bars.push({ delay: `${Math.floor(Math.random() * 500)}ms`, height: '1rem' });
      this.barInterval = window.setInterval(() => this.bars.forEach((bar) => { bar.height = `${Math.floor(Math.random() * this.amplitude)}rem`; }), 250); // eslint-disable-line no-param-reassign
    },
  },
  mounted() {
    this.generate();
  },
  props: {
    mood: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/colors'

.fake-waveform
  display: flex
  align-items: center
  justify-content: center
  height: 10rem
  margin-left: 1rem

  .bar
    display: inline-block
    width: 0.5rem
    height: 1rem
    background-image: url('../assets/images/gold-foil-tileable.jpg')
    background-color: $gold
    background-position: 0 center
    background-attachment: fixed
    background-size: (1332 / 2)px
    transition: height 500ms ease
    border-radius: 0.25rem
    transition: height 500ms ease

    &:not(:last-child)
      margin-right: 1rem
</style>

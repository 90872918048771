<template>
  <div class="home">
    <video autoplay class="bg-vid" loop muted playsinline poster="../assets/images/fire-poster.jpg" ref="bgVid">
      <source src="../assets/videos/fire.mp4" type="video/mp4">
    </video>
    <div class="overlay" :class="{ hovering }">
      <div class="content-wrapper">
        <header>
          <FakeWaveForm :mood="christmasMood" />
          <h1>Ding, dong merrily on high!</h1>
          <FakeWaveForm :mood="christmasMood" />
        </header>
        <audio loop :src="require('../assets/sounds/Feuer.mp3')" ref="fireSound" />
        <transition mode="out-in">
          <section v-if="currentPage === 'enter'" class="page enter" key="enter">
            <p>This website uses sound. Please make sure you have your headphones / speakers set to an appropriate volume.</p>
            <button type="button" @click="enterExperience">Enter the experience</button>
          </section>
          <section v-else-if="currentPage === 'soundboard'" class="page soundboard" key="soundboard">
            <div class="buttons">
              <SoundbarItem v-for="(item, index) in sounds" :alt="item.alt" :class="{ interacted }" :icon="item.icon" :key="index" :playing="item.playing" :sound="item.sound" :volume="item.volume || globalVolume" @click="playItem(item)" />
            </div>
            <ChristmasMoodMeter :mood="christmasMood" />
          </section>
          <section v-else-if="currentPage === 'imprint'" class="page imprint" key="imprint">
            <h2>Imprint</h2>
            <p>Flavour Kommunikation GmbH<br>Kellerstraße 27<br>81667 Munich, Germany</p>
            <p>Geschäftsführer: Roland Braun<br>Gerichtsstand: München HRB 131198<br>UID: DE 205 373 062</p>
            <p>This page does not collect any form of personal data and doesn’t leave behind any cookies.</p>
            <h3>Sources</h3>
            <ul>
              <li>“Erwachsene Stimmen” – mistletoe (<a href="https://audiyou.de" rel="nofollow noreferrer noopener" target="_blank">audiyou.de</a>)</li>
              <li>“Blockflöte mit einfachem Lied bespielt”, “Dichtes und gleichförmiges, helles Brutzeln in der Bratpfanne” – <a href="https://hoerspielbox.de" rel="nofollow noreferrer noopener" target="_blank">hoerspielbox.de</a></li>
              <li>“Blockflöte üben” – Kinder der Klasse 1b der Grundschule Wörth/Wiesent (<a href="https://geräuschesammler.de/" rel="nofollow noreferrer noopener" target="_blank">geräuschesammler.de</a>)</li>
              <li>“Kinderlachen”, “Spitze Kinderschreie”, “Kreischende Kinder” – <a href="https://geräuschesammler.de/" rel="nofollow noreferrer noopener" target="_blank">geräuschesammler.de</a></li>
              <li>“4x schnelles bimmeln, “21x schnelles bimmeln” – <a href="https://salamisound.com/" rel="nofollow noreferrer noopener" target="_blank">salamisound.com</a></li>
              <li>“funny girl laugh sound” – Mike Koenig (<a href="https://soundbible.com/" rel="nofollow noreferrer noopener" target="_blank">soundbible.com</a>)</li>
            </ul>
            <button type="button" @click="currentPage = previousPage || 'enter'">Back</button>
          </section>
        </transition>
      </div>
      <transition mode="out-in">
        <div v-show="currentPage === 'soundboard'" class="share-buttons" :class="{ faded: currentPage === 'enter' || (playing && !hovering) }">
          <button v-if="shareAPI" @click="share">
            <img alt="A share icon" src="../assets/images/icons/share_gold.png">
          </button>
          <a v-if="!shareAPI" :href="`http://www.facebook.com/sharer.php?u=${url}`" rel="noreferrer noopener nofollow" target="_blank" title="Share on Facebook">
            <img src="../assets/images/icons/fb_gold.png" alt="Facebook">
          </a>
          <a v-if="!shareAPI" :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`" rel="noreferrer noopener nofollow" target="_blank" title="Share on LinkedIn">
            <img src="../assets/images/icons/LI-In-Bug_gold.png" alt="LinkedIn">
          </a>
          <button class="copy" @click="copyToClipboard">
            <transition mode="out-in">
              <img v-if="!copyMessage" alt="A copy icon" src="../assets/images/icons/Copy_URL_gold.png">
              <p v-else>{{copyMessage}}</p>
            </transition>
          </button>
        </div>
      </transition>
      <footer>
        <p>
          <span>© 2020 </span>
          <a class="clickable" href="https://flavour-kommunikation.de" target="_blank" rel="noopener noreferrer">Flavour Kommunikation</a>
          <span> · </span>
          <span class="clickable" tabindex="0" @click="currentPage = 'imprint'">Imprint</span>
          <span v-if="installPrompt"> · </span>
          <span v-if="installPrompt" class="clickable" tabindex="0" @click="installPrompt.prompt()">Install as an App</span>
        </p>
      </footer>
    </div>
    <button v-show="currentPage !== 'enter'" class="global-play-pause" :class="{ faded: currentPage === 'enter' || (playing && !hovering) }" type="button" @click="handleGlobalPlayPause">
      <transition mode="out-in">
        <img v-if="!playing" src="../assets/images/icons/Play.png" alt="Ein Play-Icon" key="play">
        <img v-else src="../assets/images/icons/Pause.png" alt="Ein Pause-Icon" key="pause">
      </transition>
    </button>
  </div>
</template>

<script>
// @ is an alias to /src
import ChristmasMoodMeter from '../components/ChristmasMoodMeter.vue';
import FakeWaveForm from '../components/FakeWaveForm.vue';
import SoundbarItem from '../components/SoundBarItem.vue';

import iconFloete from '../assets/images/icons/Floete.png';
import soundFloete from '../assets/sounds/Floete.mp3';
import iconGespraeche from '../assets/images/icons/Gespraeche.png';
import soundGespraeche from '../assets/sounds/Gespraeche.mp3';
import iconGloeckchen from '../assets/images/icons/Gloeckchen.png';
import soundGloeckchen from '../assets/sounds/Gloeckchen.mp3';
import iconKatze from '../assets/images/icons/Katze.png';
import soundKatze from '../assets/sounds/Katze.mp3';
import iconKinder from '../assets/images/icons/Kinder.png';
import soundKinder from '../assets/sounds/Kinder.mp3';
import iconKochen from '../assets/images/icons/Kochen.png';
import soundKochen from '../assets/sounds/Kochen.mp3';
import iconNote from '../assets/images/icons/Note.png';
import soundNote from '../assets/sounds/Note.mp3';
import iconSchnarchen from '../assets/images/icons/Schnarchen.png';
import soundSchnarchen from '../assets/sounds/Schnarchen.mp3';

export default {
  beforeUnmount() {
    window.removeEventListener('mouseenter', this.showOverlay);
    window.removeEventListener('touchstart', this.showOverlay);
    window.removeEventListener('mousemove', this.showOverlay, { passive: true });
    document.documentElement.removeEventListener('mouseleave', this.hideOverlay);
  },
  components: {
    ChristmasMoodMeter,
    FakeWaveForm,
    SoundbarItem,
  },
  computed: {
    christmasMood() {
      return this.sounds.filter((sound) => sound.playing).length / this.sounds.length;
    },
    clipboardAPI() {
      return Boolean(navigator && navigator.clipboard && navigator.clipboard.writeText);
    },
    shareAPI() {
      return Boolean(navigator && navigator.share);
    },
  },
  created() {
    // catch the install prompt, so we can show the toast later
    window.addEventListener('beforeinstallprompt', (prompt) => {
      prompt.preventDefault();
      this.installPrompt = prompt;
    });
  },
  data() {
    return {
      copyMessage: '',
      currentPage: 'enter',
      globalVolume: 0.25,
      hovering: true,
      hoverTimeout: null,
      installPrompt: null,
      interacted: false,
      playing: true,
      previousPage: '',
      sounds: [
        {
          alt: 'Ein Icon zweier Sprechblasen',
          icon: iconGespraeche,
          playing: false,
          sound: soundGespraeche,
        },
        {
          alt: 'Ein Icon eines Glöckchens',
          icon: iconGloeckchen,
          playing: false,
          sound: soundGloeckchen,
        },
        {
          alt: 'Ein Icon einer Katze',
          icon: iconKatze,
          playing: false,
          sound: soundKatze,
        },
        {
          alt: 'Ein Icon einer Musik-Note',
          icon: iconNote,
          playing: false,
          sound: soundNote,
        },
        {
          alt: 'Ein Icon einer dampfenden Bratpfanne',
          icon: iconKochen,
          playing: false,
          sound: soundKochen,
        },
        {
          alt: 'Ein Icon von drei aufsteigenden z',
          icon: iconSchnarchen,
          playing: false,
          sound: soundSchnarchen,
          volume: 1,
        },
        {
          alt: 'Ein Icon eines schreinden Mädchens',
          icon: iconKinder,
          playing: false,
          sound: soundKinder,
        },
        {
          alt: 'Ein Icon einer Flöte',
          icon: iconFloete,
          playing: false,
          sound: soundFloete,
        },
      ],
      timeToFadeOut: 2 * 1000, // 2 seconds
      url: 'https://xmas.flavour-kommunikation.de',
      werePlaying: [],
    };
  },
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.url);
        this.copyMessage = 'Copied!';
      } catch (err) {
        this.copyMessage = 'Error…';
      } finally {
        window.setTimeout(() => { this.copyMessage = ''; }, 5000);
      }
    },
    enterExperience() {
      window.addEventListener('mouseenter', this.showOverlay);
      window.addEventListener('touchstart', this.showOverlay);
      window.addEventListener('mousemove', this.showOverlay, { passive: true });
      document.documentElement.addEventListener('mouseleave', this.hideOverlay);

      this.hoverTimeout = window.setTimeout(() => {
        this.hideOverlay();
      }, this.timeToFadeOut);

      this.currentPage = 'soundboard';
      this.$refs.bgVid.play();
      this.$refs.fireSound.play();
    },
    handleGlobalPlayPause() {
      if (this.playing) {
        // we want to pause
        this.$refs.bgVid.pause();
        this.$refs.fireSound.pause();
        this.werePlaying = this.sounds.reduce((acc, sound, index) => {
          if (sound.playing) {
            acc.push(index);
            sound.playing = false; // eslint-disable-line no-param-reassign
          }
          return acc;
        }, []);
        this.playing = false;
      } else {
        // we want to play
        this.$refs.bgVid.play();
        this.$refs.fireSound.play();
        this.werePlaying.forEach((index) => { this.sounds[index].playing = true; });
        this.werePlaying = [];
        this.playing = true;
      }
    },
    hideOverlay() {
      if (this.hoverTimeout) {
        window.clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
      }
      this.hovering = false;
    },
    playItem(item) {
      if (!this.interacted) this.interacted = true;
      item.playing = !item.playing; // eslint-disable-line no-param-reassign
    },
    async share() {
      try {
        await navigator.share({
          title: 'Merry Christmas 2020 | Flavour Kommunikation',
          text: 'Check out our 2020 Christmas experience!',
          url: this.url,
        });
      } catch (err) {
        // do nothing
      }
    },
    showOverlay() {
      if (this.hoverTimeout) {
        window.clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
      }
      this.hovering = true;
      this.hoverTimeout = window.setTimeout(() => {
        this.hideOverlay();
      }, this.timeToFadeOut);
    },
  },
  watch: {
    currentPage(nv, ov) {
      this.previousPage = ov;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/colors'

$mobile = "only screen and (max-width: 40rem)"
$tooSmallForBars = "only screen and (max-width: 60rem)"

.home
  position: relative
  height: 100vh
  overflow: hidden
  user-select: none

  .bg-vid
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100vh
    object-fit: cover
    z-index: -1

  .overlay
    display: flex
    flex-direction: column
    align-items: center
    background-color: alpha(black, 0.68)
    height: 100%
    width: 100%
    opacity: 0
    visibility: hidden
    cursor: none
    overflow-y: auto
    overflow-x: hidden
    transition: opacity 500ms ease, visibility 500ms ease

    &.hovering
      opacity: 1
      visibility: visible
      cursor: initial

    .content-wrapper
      padding: (160 / 16)rem 0
      width: 100%
      margin-top: auto
      margin-bottom: auto

      @media $mobile
        padding: (80 / 16)rem 0

      header
        display: flex
        justify-content: space-between
        align-items: center
        margin: 0 auto (96 / 16)rem auto
        padding: 0 0.5rem

        @media $tooSmallForBars
          flex-direction: column
          margin-bottom: (48 / 16)rem

        h1
          background-image: url('../assets/images/gold-foil-tileable.jpg')
          background-color: $gold
          background-clip: text
          background-position: 0 center
          background-size: (1332 / 2)px
          color: transparent
          font-size: 4rem
          line-height: 1
          max-width: (584 / 16)rem
          overflow: hidden
          text-align: center

          @media $mobile
            font-size: 3rem

        .fake-waveform
          width: 33%
          margin: 0 auto

          &:last-child
            @media $tooSmallForBars
              display: none

      .page
        &.v-enter-active,
        &.v-leave-active
          transition: opacity 500ms ease, transform 500ms ease

          &.v-enter-from
            opacity: 0
            transform: scale(0.8)

          &.v-leave-to
            opacity: 0
            transform: scale(1.2)

        &.enter
          text-align: center
          padding: 0 2rem

        &.soundboard
          .buttons
            display: flex
            justify-content: center
            text-align: center
            flex-wrap: wrap
            max-width: (1200 / 16)rem
            margin: 0 auto

            .soundbar-item
              margin: 2.5rem

              @media $mobile
                margin: 2rem

              &:first-child:not(.interacted)
                animation: wiggle 1s ease infinite

                @keyframes wiggle
                  50%
                    transform: scale(1.2) rotate(0deg)
                    opacity: 1

          .christmas-mood-meter
            padding: 0 1rem

        &.imprint
          font-family: sans-serif
          font-size: 1.125rem
          margin: 0 auto
          padding: 0 1rem
          max-width: (640 / 16)rem

          ul
            margin-bottom: 3rem

  footer
    font-family: sans-serif
    font-size: 1rem
    text-align: center
    padding: 0 1rem

    span, a
      opacity: 0.4

      &.clickable
        transition: opacity 200ms ease
        cursor: pointer

        &:hover,
        &:focus
          outline: none
          opacity: 1

  .global-play-pause,
  .share-buttons
    position: fixed
    bottom: 2rem
    right: 2rem
    padding: 0
    border: none
    opacity: 0.4
    transition: opacity 200ms ease

    &:hover,
    &:focus
      opacity: 1

    &.faded
      opacity: 0
      pointer-events: none

    img
      width: (64 / 16)rem
      display: block

    .v-enter-active,
    .v-leave-active
      transition: opacity 200ms ease, transform 200ms ease

      &.v-enter-from,
      &.v-leave-to
        opacity: 0
        transform: scale(0.8)

  .share-buttons
    opacity: 1
    left: 2rem
    right: auto
    display: flex
    align-items: center
    // height: (64 / 16)rem

    @media $mobile
      position: static
      margin-bottom: 1rem

      > :first-child:not(:only-child)
        margin-right: 2rem

    &.v-enter-active,
    &.v-leave-active
      transition: opacity 500ms ease 500ms, transform 500ms ease 500ms // because the other one does an in/out

      &.v-enter-from,
      &.v-leave-to
        opacity: 0
        transform: scale(0.8)

    > :not(:last-child)
      margin-right: 2rem

      @media $mobile
        margin-right: 0

    button
      padding: 0
      border: none
      border-radius: 0

      &.copy
        @media $mobile
          display: none

      p
        font-family: sans-serif
        font-size: 1rem
        margin: 0

    img
      height: (32 / 16)rem
      width: auto

    a,
    button
      transition: opacity 200ms ease
      opacity: 0.4

      &:hover,
      &:focus
        outline: none
        opacity: 1

</style>

import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import 'fontsource-trade-winds';

import './assets/styles/base.styl';

createApp(App).use(router).mount('#app');

<template lang="html">
  <button class="soundbar-item" :class="{ active: playing }" type="button">
    <img :alt="alt" :src="icon" height="60">
    <audio loop :src="sound" ref="sound" />
  </button>
</template>

<script>
export default {
  mounted() {
    this.$refs.sound.volume = this.volume;
    if (this.playing) this.$refs.sound.play();
  },
  props: {
    alt: String,
    icon: String,
    playing: Boolean,
    sound: String,
    volume: Number,
  },
  watch: {
    playing(nv) {
      if (nv) this.$refs.sound.play();
      else this.$refs.sound.pause();
    },
    volume(nv) {
      this.$refs.sound.volume = nv;
    },
  },
};
</script>

<style lang="stylus" scoped>
.soundbar-item
  padding: 0
  border: none
  opacity: 0.4
  transition: opacity 200ms ease

  &:hover,
  &:focus
    opacity: 0.8

  &.active
    opacity: 1

  img
    display: block

</style>

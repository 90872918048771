<template lang="html">
  <div class="christmas-mood-meter">
    <div class="meter">
      <div class="track" />
      <div class="progress" :style="{ width: `${mood * 100}%` }" />
    </div>
    <p>You are in {{(mood * 100).toFixed(0)}} % Christmas mood</p>
  </div>
</template>

<script>
export default {
  props: {
    mood: Number,
  },
};
</script>

<style lang="stylus" scoped>
@require '../assets/styles/base'

.christmas-mood-meter
  .meter
    position: relative
    max-width: (200 / 16)rem
    margin: 2rem auto

    .track
      background-color: $gold
      position: absolute
      width: 100%
      height: 2px
      top: calc(50% - 1px)
      border-radius: 1px
      opacity: 0.5

    .progress
      height: 0.5rem
      background-image: url('../assets/images/gold-foil-tileable.jpg')
      background-color: $gold
      background-position: 0 center
      background-size: (1332 / 2)px
      transition: width 500ms ease
      border-radius: 0.25rem

  p
    background-image: url('../assets/images/gold-foil-tileable.jpg')
    background-color: $gold
    background-clip: text
    background-position: 0 center
    background-size: (1332 / 2)px
    color: transparent
    text-align: center
</style>
